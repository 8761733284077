<template>
  <van-nav-bar
    :title="title"
  />
  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <div v-for="item in items" :key="item" style="margin:7px 0 0 0;padding:15px;background:#fff;">
      <div style="display: flex;">
        <van-image
          round
          width="3rem"
          height="3rem"
          :src="item.avatar"
          style="vertical-align:middle;"
        />
        <div style="margin-left: 12px;">
          <p style="margin-bottom: 4px;">{{item.name}}</p>
          <span style="font-size: 14px;">{{ item.phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3') }}</span>
        </div>
      </div>
      <div style="display: flex; margin-top: 10px;font-size: 14px;position:relative;">
        <p style="margin-right: 25px;line-height: 28px;">累计收益：¥{{item.profit || 0}}</p>
        <p style="line-height: 28px;">余额：¥{{item.balance || 0}}</p>
        <div style="position: absolute;right: 0;padding: 5px 10px;background-color: #1989fa;border-radius: 5px;color: #fff;font-size: 13px;">查看个人订单></div>
      </div>
      <div @click="toOrder(item)" style="margin-top: 10px;padding-top: 10px;border-top: 1px solid #f6f6f6;font-size: 13px;">受邀时间：{{item.createTime}}</div>
    </div>
  </van-list>
</template>

<script>
import { toRefs, reactive, onMounted, inject, watch } from 'vue'

export default {
  components: {
  },
  setup () {
    const post = inject('post')
    const useRoute = inject('useRoute')
    const useRouter = inject('useRouter')
    const Cookies = inject('Cookies')
    const state = reactive({
      searchKey: '',
      page: 1,
      finished: false,
      loading: false,
      items: [],
      inviterId: Cookies.get('buyerId'),
      storeId: Cookies.get('storeId'),
      title: ''
    })
    const onClick = (item) => {
      state.inviterId = item.inviteeId
      reset()
    }
    const onClear = () => {
      reset()
    }
    const onSearch = (value) => {
      reset()
    }
    const getData = () => {
      post('/invite.list', {
        pageNum: state.page,
        storeId: state.storeId,
        inviterId: state.inviterId
      }).then(res => {
        for (const v of res.data.content) {
          state.items.push({
            avatar: v.invitee.avatar || v.invitee.user.avatar,
            phone: v.invitee.user.phone,
            name: v.invitee.name || v.invitee.user.nickName || v.invitee.user.name,
            balance: v.invitee.balance,
            profit: v.invitee.profit,
            createTime: v.createTime,
            buyerId: v.invitee.buyerId
          })
        }
        // state.items = [...state.items, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '我的邀请（' + res.data.totalElements + '人）'
      })
    }
    const reset = () => {
      state.items = []
      state.page = 1
      state.finished = false
      state.loading = false
    }
    const init = () => {
      // alert('13456789012'.replace(/(\d{3})(\d{4})(\d{4})/, '$1****$3'))
    }
    const toOrder = (item) => {
      useRouter.push({
        name: 'OrderList',
        query: {
          userPhone: item.phone,
          userName: item.name,
          buyerId: item.buyerId
          // tab: 6
        }
      })
    }
    watch(() => useRoute.query, (to, previous) => {
    })
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onSearch,
      onClear,
      onClick,
      toOrder
    }
  }
}
</script>

<style scoped>
</style>
